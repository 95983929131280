export default {
  state: {
    user: null,
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    SET_USER: (state, user) => (state.user = user)
  },
  actions: {
  }
}