import Vue from 'vue'
import Vuex from 'vuex'
import error from './modules/error'
import user from './modules/user'
import menus from "./modules/menus";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    error,
    user,
    menus
  },
  state: {
    pageLoading: false,
    sectionLoading: false,
    buttonLoading: false,
  },
  mutations: {
    SET_PAGE_LOADING(state, payload) {
      state.pageLoading = payload
    },
    SET_SECTION_LOADING(state, payload) {
      state.sectionLoading = payload
    },
    SET_BUTTON_LOADING(state, payload) {
      state.buttonLoading = payload
    },
  },
  actions: {
  },
})
