import { mapMutations, mapGetters } from "vuex";

export default {
  methods: {
    ...mapMutations([
      "setShowError",
      "setError"
    ])
  },
  computed: {
    ...mapGetters([
        'showError',
        'errors'
    ]),
    showErr: {
      get () { return this.showError },
      set (val) { this.setShowError(val) },
    },
    err: {
      get () { return this.errors },
      set (val) { this.setError(val) }
    }
  }
}