import vm from "@/main";

export default {
  state: {
    users: null,
    services: null,
    childServices: [],
    catalogs: null,
    childCatalogs: [],
    news: [],
    partners: [],
    about: {},
    investor: {},
    contacts: [],
  },
  getters: {
    getUsers: (state) => state.users,
    getServices: (state) => state.services,
    getChildServices: (state) => state.childServices,
    getCatalogs: (state) => state.catalogs,
    getChildCatalogs: (state) => state.childCatalogs,
    getNews: (state) => state.news,
    getPartners: (state) => state.partners,
    getAbout: (state) => state.about,
    getInvestor: (state) => state.investor,
    getContacts: (state) => state.contacts,
  },
  mutations: {
    SET_USERS: (state, users) => (state.users = users),
    SET_SERVICES: (state, services) => (state.services = services),
    SET_CHILD_SERVICES: (state, services) => (state.childServices = services),

    SET_CATALOGS: (state, catalogs) => (state.catalogs = catalogs),
    SET_CHILD_CATALOGS: (state, catalogs) => (state.childCatalogs = catalogs),

    SET_NEWS: (state, payload) => (state.news = payload),
    SET_PARTNERS: (state, payload) => (state.partners = payload),
    SET_ABOUT: (state, payload) => (state.about = payload),
    SET_INVESTOR: (state, payload) => (state.investor = payload),
    SET_CONTACTS: (state, payload) => (state.contacts = payload),
  },
  actions: {
    /////////////  User start  ///////////////////

    async fetchUsers({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("user?limit=10&offset=0");
        commit("SET_USERS", res.rows);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async creatUser({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.post("user", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    async updateUser({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("user", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    async deleteUser({ commit }, id) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.delete(`user/${id}`);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  User end  ///////////////////

    /////////////  Service start  ///////////////////

    async fetchServices({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("service");
        commit("SET_SERVICES", res.rows);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async fetchChildServices({ commit }, serviceId) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get(`service/${serviceId}`);
        commit("SET_CHILD_SERVICES", res.Services);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async creatService({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.post("service", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
        return res;
      } catch (e) {
        console.log(e);
      }
    },

    async updateService({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("service", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
        return res;
      } catch (e) {
        console.log(e);
      }
    },

    async deleteService({ commit }, id) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.delete(`service/${id}`);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  Service end  ///////////////////

    /////////////  Catalogs start  ///////////////////

    async fetchCatalogs({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get(`service/68`);
        commit("SET_CATALOGS", res.Services);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async fetchChildCatalogs({ commit }, catalogId) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get(`service/${catalogId}`);
        commit("SET_CHILD_CATALOGS", res.Services);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    /////////////  Catalogs end  ///////////////////

    /////////////  News start  ///////////////////

    async fetchNews({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("post");
        commit("SET_NEWS", res.rows);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async createNews({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.post("post", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
        return res;
      } catch (e) {
        console.log(e);
      }
    },

    async updateNews({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("post", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
        return res;
      } catch (e) {
        console.log(e);
      }
    },

    async deleteNews({ commit }, id) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.delete(`post/${id}`);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  News end  ///////////////////

    /////////////  Partners start  ///////////////////

    async fetchPartners({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("partner");
        commit("SET_PARTNERS", res.rows);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async createPartners({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.post("partner", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
        return res;
      } catch (e) {
        console.log(e);
      }
    },

    async updatePartners({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("partner", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
        return res;
      } catch (e) {
        console.log(e);
      }
    },

    async deletePartners({ commit }, id) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.delete(`partner/${id}`);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  Partners end  ///////////////////

    /////////////  About start  ///////////////////

    async fetchAbout({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("about");
        res.images = JSON.parse(res.images);
        commit("SET_ABOUT", res);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async updateAbout({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("about", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  About end  ///////////////////

    /////////////  Investor start  ///////////////////

    async fetchInvestor({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("investor");
        commit("SET_INVESTOR", res);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async updateInvestor({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("investor", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  Investor end  ///////////////////

    /////////////  Contact start  ///////////////////

    async fetchContacts({ commit }) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.get("contact");
        commit("SET_CONTACTS", res.rows);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        commit("SET_PAGE_LOADING", false, { root: true });
      }
    },

    async updateContact({ commit }, dataForm) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.put("contact", dataForm);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    async deleteContact({ commit }, id) {
      try {
        commit("SET_PAGE_LOADING", true, { root: true });
        const res = await vm.$api.delete(`contact/${id}`);
        commit("SET_PAGE_LOADING", false, { root: true });
      } catch (e) {
        console.log(e);
      }
    },

    /////////////  Contact end  ///////////////////
  },
};
