import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'
import token from "../services/token"

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/',
    component:Layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/User/List')
      },
      {
        path: '/services',
        name: 'services',
        component: () => import('@/views/Services/List')
      },
      {
        path: '/catalogs',
        name: 'catalogs',
        component: () => import('@/views/Catalogs/List')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/News/List')
      },
      {
        path: '/partners',
        name: 'partners',
        component: () => import('@/views/Partners/List')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About/List')
      },
      {
        path: '/investor',
        name: 'investor',
        component: () => import('@/views/Investor/List')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/Contact/List')
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  let hasToken = !!token.getToken();

  if (!isPublic && !hasToken) {
    return next({
      name: 'login'
    })
  }

  if (hasToken && onlyWhenLoggedOut) {
    return next({
      name: 'home'
    })
  }

  next();
})

export default router
