<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer"
                         color="primary"
                         dark
                         app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Salico Trade Admin
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <SidebarList/>
    </v-navigation-drawer>

    <v-app-bar app
               color="white"
               flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>
      <v-spacer/>
      <v-btn @click="logout"
             text
             color="error">
        logout
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <div class="pa-6">
        <router-view/>
      </div>
    </v-main>

    <v-snackbar v-model="showErr"
                :timeout="5000"
                color="red" min-width="500">
      {{ err }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs"
                @click="showErr = false">
          mdi-close-circle-outline
        </v-icon>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import SidebarList from "@/components/Layout/SidebarList";
import token from "@/services/token";

export default {
  name: "layout",
  data: () => ({
    drawer: true
  }),
  components: { SidebarList },
  methods: {

    async logout () {
      token.removeToken();
      await this.$router.push('/login');
    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>