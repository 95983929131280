<template>
  <v-list>
    <v-list-item-group v-model="link" color="white">
      <template v-for="(li, i) in links">
        <template v-if="!li.children">
          <v-list-item router :to="li.route">
            <v-list-item-icon>
              <v-icon class="white--text">{{ li.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ li.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-group color="white"
                        :value="true">
            <template v-slot:activator>
              <v-list-item-title>{{ li.text }}</v-list-item-title>
            </template>
            <v-list-item class="pl-8 py-0" v-for="(childItem, index) in li.children"
                         router
                         :to="childItem.route">
              <v-list-item-icon>
                <v-icon class="white--text">{{ childItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{ childItem.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: "SidebarList",
    data: () => ({
      link: 0,
      links: [
        { text: 'Users', route: 'user', icon: 'mdi-account-group'},
        { text: 'Services', route: 'services', icon: 'mdi-animation-outline'},
        { text: 'Catalogs', route: 'catalogs', icon: 'mdi-apps'},
        { text: 'News', route: 'news', icon: 'mdi-newspaper-variant-multiple'},
        { text: 'Partners', route: 'partners', icon: 'mdi-handshake-outline'},
        { text: 'About', route: 'about', icon: 'mdi-information-outline'},
        { text: 'Investor', route: 'investor', icon: 'mdi-account-cash-outline'},
        { text: 'Contact', route: 'contact', icon: 'mdi-card-account-phone-outline'},
      ]
    }),
  }
</script>

<style scoped>

</style>
