import axios from "axios";

const tokenKey = 'Authorization';
const refreshTokenKey = 'Refresh';
const tokenExpire = 'TokenExpire';

const TokenService = {
  getToken() {
    return localStorage.getItem(tokenKey);
  },
  saveToken(token) {
    localStorage.setItem(tokenKey, token);
  },
  saveRefreshToken(token) {
    localStorage.setItem(refreshTokenKey, token);
  },
  tokenExpireDate(expireTime) {
    localStorage.setItem(tokenExpire, expireTime);
  },
  removeExpireDate () {
    localStorage.removeItem(tokenExpire)
  },
  removeToken() {
    localStorage.removeItem(tokenKey);
  },
  removeRefreshToken() {
    localStorage.removeItem(refreshTokenKey);
  },
  getRefreshToken() {
    return localStorage.getItem(refreshTokenKey);
  },
};

export default TokenService;
