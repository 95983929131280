import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from "axios";
import token from "@/services/token";
import api from "@/services/api"
import errMixin from "@/mixins/errMixin";
import SectionLoader from './components/Global/Loader/SectionLoader'
import '@/assets/style.scss';
import { mask } from 'vue-the-mask'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueUploadMultipleImage from 'vue-upload-multiple-image'

Vue.use( CKEditor );
Vue.component('upload-image', VueUploadMultipleImage,);

Vue.directive('mask', mask);

import VueLazyload from 'vue-lazyload';

axios.interceptors.response.use(res => res, error => {
  if (error.response && error.response.status === 401) {
    token.removeToken();
    token.removeRefreshToken();
    router.push({name: 'login'});
  }
  return Promise.reject(error)
});

Vue.use(api);
Vue.use(VueLazyload);
Vue.component('SectionLoader', SectionLoader);

Vue.config.productionTip = false;

Vue.mixin(errMixin);

export default new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
