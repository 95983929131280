<template>
  <v-app>
    <router-view />
    <Loader/>
  </v-app>
</template>

<script>
  import Loader from './components/Global/Loader'
  export default {
    name: 'App',

    components: {
      Loader
    },

    data: () => ({
      //
    }),
  };
</script>
