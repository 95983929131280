<template>
  <div class="text-center ">
    <v-dialog
      v-model="$store.state.pageLoading"
      persistent
      width="500"
      class="loader-background"
    >
        <v-card
          color="white"
          dark
        >
          <v-card-text>
            .
            <v-progress-linear
              indeterminate
              rounded
              :color="color"
              class="my-5"
              height="10"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  computed: {
    color() {
      const colors = ['primary', 'red', 'green'];
      return colors[Math.floor(Math.random() * 3)]
    }
  }
}
</script>
<style lang="scss" scoped>
.page-loader {
  position: absolute;
  top: 30%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
</style>
