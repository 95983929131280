<template>
  <div class="text-center section-loader">
    <v-progress-circular
      :size="70"
      :width="7"
      :color="color"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  computed: {
    color() {
      const colors = ['#026ba2', '#026ba2'];
      return colors[Math.floor(Math.random() * 2)]
    }
  }
}
</script>

<style lang="sass">
.section-loader
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
</style>
