export default {
  state: {
    showError: false,
    errors: ''
  },
  getters: {
    showError: state => state.showError,
    errors: state => state.errors
  },
  mutations: {
    setShowError: (state, show) => (state.showError = show),
    setError: (state, errors) => (state.errors = errors)
  }
}